import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import Welcome from './Welcome';

import Banner from 'components/Features/Banner';
import { BANNER_LOCATIONS } from 'components/AppRoot/StaticResources/constants';

import {
  Button,
  TextField,
} from '@mui/material';

import {
  FormWrapper,
  LoadingOverlay,
  StyledLink,
  allNotificationsHide,
} from '@frontend/common';

import { userLogin } from 'components/AppRoot/Navigation/actions';

import styles from './styles.module.css';

const select = (state) => ({
  isValid: state.session.isValid,
  errors: state.session.errors,
  loadingPublicVars: state.static.loadingPublicVars,
  webMessages: state.static.webMessages,
});

export class Login extends Component {

  static propTypes = {
    userLogin: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    errors: PropTypes.array,
    allNotificationsHide: PropTypes.func.isRequired,
    webMessages: PropTypes.object.isRequired,
    loadingPublicVars: PropTypes.bool.isRequired,
  };

  state = {
    username: '',
    password: '',
    formErrors: {},
    loading: false,
  };

  formValidate = () => {
    let isValid = false;
    let formErrors = {};

    if (!this.state.username) {
      formErrors.username = 'Username is required.';
    }

    if (!this.state.password) {
      formErrors.password = 'Password is required.';
    }

    if (this.state.username && this.state.password) {
      formErrors = {};
      isValid = true;
    }

    this.setState({
      formErrors
    });

    return isValid;
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    this.props.allNotificationsHide();

    if (this.formValidate()) {
      document.getElementById('loginUsername').blur();
      document.getElementById('loginPassword').blur();

      const params = {
        username: this.state.username,
        password: this.state.password
      };

      this.setState({ loading: true });

      this.props.userLogin(params)
        .then(() => {
          try {
            window.QSI.API.run(); // stimulate Targeting.php
            window.QSI.API.unload();
            window.QSI.API.load();
            window.QSI.API.run(); // stimulate Targeting.php
          }
          catch (error) {
            // return null;
          }
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  onInputChange = (inputName, value) => {
    const clonedErrors = cloneDeep(this.state.formErrors);

    if (value.length > 0 && this.state.formErrors[inputName]) {
      clonedErrors[inputName] = '';
    }

    this.setState({
      [inputName]: value,
      formErrors: clonedErrors,
    });
  }

  render() {
    /* eslint-disable indent */
    const { loadingPublicVars, webMessages } = this.props;

    return (
      <div>
        <Banner show={Boolean(webMessages[BANNER_LOCATIONS.LOGIN])} body={webMessages[BANNER_LOCATIONS.LOGIN]} />
        <FormWrapper
          onSubmit={this.onFormSubmit}
          title={'Login'}
        >
          <LoadingOverlay show={this.state.loading || loadingPublicVars} width='100%'>
            <form
              className='formContainer'
              onSubmit={this.onFormSubmit}
            >
              <TextField
                id='loginUsername'
                label='Username'
                value={this.state.username}
                onChange={(e) => this.onInputChange('username', e.target.value)}
                disabled={this.state.loading}
                fullWidth
                error={Boolean(this.state.formErrors.username)}
                helperText={this.state.formErrors.username}
              />
              <TextField
                id='loginPassword'
                label='Password'
                type='password'
                value={this.state.password}
                onChange={(e) => this.onInputChange('password', e.target.value)}
                disabled={this.state.loading}
                error={Boolean(this.state.formErrors.password)}
                helperText={this.state.formErrors.password}
                fullWidth
              />
              <div className={styles.Login_button}>
                <Button
                  type='submit'
                  variant='contained'
                  disabled={this.state.loading}
                  fullWidth
                >
                  Log In
                </Button>
              </div>
              <div className={styles.Login_linkContainer}>
                {this.state.loading
                  ? [
                    <small key='forgotPassword'>Forgot Password?</small>,
                    <small key='forgotUsername'>Forgot Username?</small>
                  ]
                  : [
                    <StyledLink to='/forgot-password' key='forgotPasswordLink'><small>Forgot Password?</small></StyledLink>,
                    <StyledLink to='/forgot-username' key='forgotUsernameLink'><small>Forgot Username?</small></StyledLink>
                  ]
                }
              </div>
            </form>
          </LoadingOverlay>
        </FormWrapper>
        <Welcome />
      </div>
    );
    /* eslint-enable indent */
  }
}

export default withRouter(connect(select, { userLogin, allNotificationsHide })(Login));
