import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { pinValidator } from 'utils/helpers/form_validation';
import { alphaNumericOnly } from 'utils/helpers/sanitation';
import PwddByAuthy from '../PwddByAuthy.jpg';

import {
  Button,
  TextField,
  Slide
} from '@mui/material';

import {
  LoadingOverlay,
  notificationShow
} from '@frontend/common';

import {
  verifyPIN,
} from '../actions';

import {
  clearStore,
  userLogout,
} from 'components/AppRoot/Navigation/actions';

import styles from '../styles.module.css';

const select = (state) => ({
  claims: state.session.claims,
  PINVerification: state.multifactor.PINVerification,
});

export class VerifyIdentity extends Component {

  static propTypes = {
    clearStore: PropTypes.func.isRequired,
    userLogout: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    claims: PropTypes.object.isRequired,
    verifyPIN: PropTypes.func.isRequired,
    PINVerification: PropTypes.object.isRequired,
  };

  state = {
    isLoading: false,
    PIN: '', 
    PINError: '',
    regMethod: '',
  };

  onInputChange = (name, value) => {
    this.setState({ [name]: value, PINError: '' });
  }

  onPINSubmit = (e) => {
    e.preventDefault();
    const { PIN } = this.state;
    const PINError = pinValidator(PIN);
    if (PINError) {
      this.setState({ PINError });
    }
    else {
      this.setState({ isLoading: true });
      // strip non alpha-numeric characters to prevent SQL injection
      const sanitizedPIN = alphaNumericOnly(PIN);
      this.props.verifyPIN(sanitizedPIN)
        .then(() => {
          const { IdentityVerified, LockedOut } = this.props.PINVerification;
          if (LockedOut) { // 2 bad PIN's provided
            this.setState({ isLoading: false });
            this.props.notificationShow('You are unable to verify your identity at this time. Please try again after 30 minutes.', 'error');
            setTimeout(() => this.logOut(), 4000);
          }
          else if (IdentityVerified) { // Good PIN
            // redirect user to 2FA registration
            this.props.history.push('/multifactor/register');
          }
          else {
            this.setState({
              isLoading: false,
              PINError: 'PIN is incorrect.'
            });
          }
        });
    }
  }

  renderPIN() {
    const { PIN, PINError, isLoading } = this.state;

    return (
      <form
        id='send-rsa-dob'
        onSubmit={this.onPINSubmit}
      >
        <p style={{ fontWeight: 'bold' }}>The device you are using is not registered with my529. Verify your identity by entering in your unique advisor PIN.</p>
        <div>
          <TextField
            disabled={isLoading}
            label='Enter Pin'
            error={Boolean(PINError)}
            helperText={PINError}
            onChange={e => this.onInputChange('PIN', e.target.value || '')}
            value={PIN}
            variant='filled'
            inputProps={{ style: { textAlign: 'center' } }}
            autoFocus
          />
        </div>
        <div className={styles.buttons}>
          <LoadingOverlay show={isLoading} width='100%'>
            <Button
              variant='contained'
              style={{ marginTop: '20px' }}
              type='submit'
              fullWidth
            >
              Verify your pin
            </Button>
          </LoadingOverlay>
        </div>


      </form>
    );
  }

  logOut = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .finally(() => {
        this.props.notificationShow('You have logged out.', 'success');
      });
    this.props.clearStore();
    this.props.history.push('/login');
  }

  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <h3>Verify Your Identity</h3>

        {this.renderPIN()}

        <Button
          color='secondary'
          variant='outlined'
          style={{ marginTop: '10px' }}
          className={styles.buttons}
          onClick={() => window.open('https://my529.org/faq-two-factor-authentication/', '_blank', 'noopener noreferrer')}
        >
          Learn More
        </Button><br />
        <img src={PwddByAuthy} alt='Powered By Authy' />
      </div>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) { // eslint-disable-line
  return <Slide direction='up' ref={ref} {...props} />;
});

export default withRouter(connect(select, {
  clearStore,
  userLogout,
  notificationShow,
  verifyPIN,
})(VerifyIdentity));