import { notificationShow } from '@frontend/common';

export const apiResponse = store => next => action => {

  const { payload } = action;

  if (payload) { // Async actions

    const errorMsg = [];
    const warningMsg = [];
    const infoMsg = [];
    const inputMsg = [];

    if (payload.status >= 299 && payload.status < 400) { // Redirection
      errorMsg.push('A temporary redirect was issued by the server.');
      window.location.href = '/';
    }
    else if (payload.status === 400 && payload.request.responseType === 'blob') {
      // payload.data may be an array of messages tucked inside the blob. 
      // Pass it through it will be handled on client side
      return next(action);
    }
    else if (payload.status === 400) { // payload.data may be an array of messages.
      if (payload.data && payload.data.length > 0) {
        payload.data.forEach(msg => {
          switch (msg.MessageType) {
            case 0: infoMsg.push(msg.Message); break;
            case 1: warningMsg.push(msg.Message); break;
            case 2: {
              if (!msg.Field) {
                errorMsg.push(msg.Message);
                break;
              }
              else if (msg.Field !== '') {
                inputMsg.push(msg.Message);
                break;
              }
              break;
            }
            case 3: break; // former 412 handled here: this contains precondition messages handled by a component
            default: break;
          }
        });
      }
      else {
        errorMsg.push('A bad request or invalid credentials were provided. No messages were provided. Status: 400.');
      }
    }
    else if (payload.status === 401) { // Likely invalid token
      errorMsg.push('Unauthorized request');
      import('../../components/AppRoot/Navigation/actions') // have to import now instead of at the beginning of file because otherwise fails in tests
        .then(({ clearStore }) => {
          store.dispatch(clearStore());
        });
    }
    else if (payload.status === 403) { // Likely user doesn't have needed Claim/Permission
      errorMsg.push('Access denied');
      import('../../components/AppRoot/Navigation/actions') // have to import now instead of at the beginning of file because otherwise fails in tests
        .then(({ clearStore }) => {
          store.dispatch(clearStore());
        });
    }
    else if (payload.status === 404) {
      errorMsg.push('Not found');
    }
    else if (payload.status > 404 && payload.status < 409) { // race condition
      errorMsg.push('You are attempting an action that is not allowed. Contact my529 if you continue to experience this issue.');
    }
    else if (payload.status === 409) {
      errorMsg.push('You are attempting to modify a record that is in the process of being modified.');
    }
    else if (payload.status === 460) {
      import('../../components/AppRoot/Navigation/actions') // have to import now instead of at the beginning of file because otherwise fails in tests
        .then(({ clearStore }) => {
          store.dispatch(clearStore());
          store.dispatch(notificationShow('Your account has been temporarily locked due to too many failed attempts. Please try again later.', 'error'));
        });
    }
    else if (payload.status > 409 && payload.status < 600) {
      errorMsg.push('There was an issue communicating with the server. Please try again later, or contact my529 if you continue to experience this issue.');
      import('./error_handler') // have to import now instead of at the beginning of file because otherwise fails in tests
        .then(({ persistClientErrors }) => {
          persistClientErrors(payload.statusText); // send the GUID to the API so it can persist the GUID into the WebErrorLog table
        });
    }

    if (inputMsg.length > 0) {
      const updatedAction = action;
      updatedAction.type = `${action.type}_ERRORS`;
      next(updatedAction);
      return Promise.reject(action);
    }

    if (errorMsg.length > 0) {
      errorMsg.forEach(error => store.dispatch(notificationShow(error, 'error')));
      const updatedAction = action;
      updatedAction.type = `${action.type}_ERRORS`;
      next(updatedAction);
      return Promise.reject(action);
    }
    else {
      warningMsg.forEach(warning => store.dispatch(notificationShow(warning, 'warning')));
      infoMsg.forEach(info => store.dispatch(notificationShow(info, 'warning')));
      return next(action);
    }
  }
  else { // Non-async actions
    return next(action);
  }
};