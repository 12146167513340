/*
*
* TwoFA Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { phoneLastFour } from 'utils/helpers/sanitation';

import RegisterPhoneAndMethod from 'components/Features/protected/Multifactor/Registration/RegisterPhoneAndMethod';
import VerifySmsOrCall from 'components/Features/protected/Multifactor/Registration/VerifySmsOrCall';
import RegisterAuthenticator from 'components/Features/protected/Multifactor/Registration/RegisterAuthenticator';
import VerifyAuthy from 'components/Features/protected/Multifactor/Shared/VerifyAuthy';
import OneTouch from 'components/Features/protected/Multifactor/Shared/OneTouch';

import {
  IconBtnTooltip,
  notificationShow,
  allNotificationsHide
} from '@frontend/common';

import {
  Button,
  TextField,
  InputAdornment,
  Dialog,
} from '@mui/material';

import {
  twoFactorRegistrationUpdate,
  getTwoFactorToken,
  savePreferredMethod
} from 'components/Features/protected/Multifactor/actions';

import PwddByAuthy from 'components/Features/protected/Multifactor/PwddByAuthy.jpg';

import { userLogout, clearStore } from 'components/AppRoot/Navigation/actions';
import { REGISTER_METHODS } from 'components/Features/protected/Multifactor/constants.js';
import { TWO_FACTOR_TYPES } from 'components/AppRoot/Navigation/constants';

import parentStyles from '../styles.module.css';
import styles from './styles.module.css';

// registration steps
const REGISTRATION_STEPS = {
  REGISTER_PHONE_AND_METHOD: 'registerPhoneAndMethod',
  VERIFY_SMS_OR_PHONE: 'verifySmsOrPhone',
  VERIFY_AUTHY: 'verifyAuthy',
  ONE_TOUCH: 'oneTouch',
  CONFIRM_OTHER_AUTHORIZATION: 'confirmOtherAuthorization',
  VERIFY_OTHER_AUTHORIZATION: 'verifyOtherAuthorization',
};

const select = (state) => ({
  userDetails: state.session.userDetails,
  preferredInfo: state.multifactor.preferredInfo,
});

export class TwoFA extends React.Component {
  static propTypes = {
    userLogout: PropTypes.func.isRequired,
    clearStore: PropTypes.func.isRequired,
    preferredInfo: PropTypes.object.isRequired,
    twoFactorRegistrationUpdate: PropTypes.func.isRequired,
    getTwoFactorToken: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
    allNotificationsHide: PropTypes.func.isRequired,
    userDetails: PropTypes.object.isRequired,
    savePreferredMethod: PropTypes.func.isRequired,
  };

  state = {
    isRegistrationOpen: false,
    registrationStep: REGISTRATION_STEPS.REGISTER_PHONE_AND_METHOD, // TODO: will change with preferred method
    isLoading: false,
    method: this.props.preferredInfo.PreferredMethod,
    phoneNumber: `${this.props.preferredInfo.CountryCode}${this.props.preferredInfo.PhoneNumber}`,
    country: {
      country: '',
      dialCode: this.props.preferredInfo.CountryCode.replace('+', ''),
    },
    isINAPPTOKEN: this.props.preferredInfo.PreferredMethod === REGISTER_METHODS.INAPPTOKEN,
  }

  logOut = () => {
    const token = sessionStorage.getItem('token');
    this.props.userLogout({ token })
      .finally(() => {
        this.props.notificationShow('You have logged out.', 'success');
        this.props.clearStore();
      });
  }

  onRegisterPhoneAndMethodUpdateSubmit = ({ phoneNumber, country, method }, hasError) => {
    this.setState({ method, phoneNumber, country });
    const countryCode = country ? `+${country.dialCode}` : this.props.preferredInfo.CountryCode;
    // remove the country code from the phone number and strip all non-numbers - api requires that
    phoneNumber = phoneNumber.replace(countryCode, '').replace(/\D/g, '');

    if (!hasError) {
      // clear all error toasts
      this.props.allNotificationsHide();

      const regParams = {
        countryCode,
        phoneNumber,
        emailAddress: this.props.userDetails.email,
        verificationMethod: method
      };

      this.setState({ isLoading: true });
      this.props.twoFactorRegistrationUpdate(regParams)
        .then(() => {
          switch (method) {
            // only sms and call will need api call to push security code to user's phone
            case REGISTER_METHODS.SMS:
            case REGISTER_METHODS.CALL:
              this.props.getTwoFactorToken(method)
                .then(() => this.setState({ isLoading: false, registrationStep: REGISTRATION_STEPS.VERIFY_SMS_OR_PHONE }))
                .catch(() => this.setState({ isLoading: false }));
              break;
            // user will get security code on their auth app
            case REGISTER_METHODS.INAPP:
              this.setState({ isLoading: false, registrationStep: REGISTRATION_STEPS.VERIFY_AUTHY });
              break;
            case REGISTER_METHODS.INAPPTOKEN:
              this.setState({ isLoading: false, registrationStep: REGISTRATION_STEPS.VERIFY_OTHER_AUTHORIZATION });
              break;

            default: // do nothing;
          }
        })
        .catch(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  onVerificationSuccess = () => {
    // save 2FA user preferences
    const params = {
      AlwaysRequired: true, // assuming always on
      PreferredMethod: this.state.method // submits selected method based on if text or phone is chosen
    };
    this.setState({ isLoading: true });
    this.props.savePreferredMethod(params)
      .then(() => {
        this.setState({ isLoading: false, isRegistrationOpen: false }); // close the dialog
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  onClickPhoneNumberEdit = () => {
    this.setState({
      isRegistrationOpen: true,
      registrationStep: REGISTRATION_STEPS.REGISTER_PHONE_AND_METHOD,
    });
  }

  onClickAuthy = () => {
    this.setState({
      isRegistrationOpen: true,
      registrationStep: REGISTRATION_STEPS.VERIFY_AUTHY,
      method: REGISTER_METHODS.VERIFY_AUTHY,
    });
  }

  onClickOtherAuthenticator = () => {
    const isINAPPTOKEN = this.props.preferredInfo.PreferredMethod === REGISTER_METHODS.INAPPTOKEN;

    if (isINAPPTOKEN) {
      this.setState({
        isRegistrationOpen: true,
        registrationStep: REGISTRATION_STEPS.CONFIRM_OTHER_AUTHORIZATION,
        isINAPPTOKEN,
      });
    }
    else {
      this.setState({
        isRegistrationOpen: true,
        registrationStep: REGISTRATION_STEPS.VERIFY_OTHER_AUTHORIZATION,
        method: REGISTER_METHODS.INAPPTOKEN,
        isINAPPTOKEN,
      });
    }
  }

  render() {
    const { preferredInfo } = this.props;
    const { isRegistrationOpen, isLoading, registrationStep, method, phoneNumber, country, isINAPPTOKEN } = this.state;

    const twoFAPhoneNumberLastFour = `(***) ***-${phoneLastFour(preferredInfo.PhoneNumber)}`;

    const useAnotherMethodButton = () => (
      <div>
        <Button
          color='secondary'
          disabled={isLoading}
          className={styles.buttons}
          onClick={() => this.setState({ registrationStep: REGISTRATION_STEPS.REGISTER_PHONE_AND_METHOD })}
        >
          Use another method
        </Button>
      </div>
    );

    const cancelButton = () => (
      <div style={{ marginTop: '10px' }}>
        <Button
          color='secondary'
          variant='text'
          onClick={() => this.setState({ isRegistrationOpen: false })}
          className={styles.buttons}
        >
          Cancel
        </Button>
      </div>
    );

    let render2FARegistrationStep;
    switch (registrationStep) {
      case REGISTRATION_STEPS.REGISTER_PHONE_AND_METHOD:
        render2FARegistrationStep = (
          <>
            <RegisterPhoneAndMethod
              isLoading={isLoading}
              onSubmit={this.onRegisterPhoneAndMethodUpdateSubmit}
              defaultMethod={''}
              defaultPhoneNumber={''}
              defaultCountry={country}
            />
            {cancelButton()}
          </>
        );
        break;

      case REGISTRATION_STEPS.VERIFY_SMS_OR_PHONE:
        render2FARegistrationStep = (
          <>
            <VerifySmsOrCall
              onVerificationSuccess={this.onVerificationSuccess}
              onVerificationFail={this.logOut}
              phoneNumber={phoneNumber}
              method={method}
            />
            {useAnotherMethodButton()}
            {cancelButton()}
          </>
        );
        break;

      case REGISTRATION_STEPS.VERIFY_AUTHY:
        render2FARegistrationStep = (
          <>
            <VerifyAuthy
              title='Enter security code or request OneTouch approval with the Authy App'
              showInstructions={true}
              onVerificationSuccess={this.onVerificationSuccess}
              onVerificationFail={this.logOut}
              onOneTouchClick={() => this.setState({ registrationStep: REGISTRATION_STEPS.ONE_TOUCH })}
            />
            {useAnotherMethodButton()}
            {cancelButton()}
          </>
        );
        break;

      case REGISTRATION_STEPS.ONE_TOUCH:
        render2FARegistrationStep = (
          <>
            <OneTouch
              onVerificationSuccess={this.onVerificationSuccess}
            />
            {useAnotherMethodButton()}
            {cancelButton()}
          </>
        );
        break;

      case REGISTRATION_STEPS.CONFIRM_OTHER_AUTHORIZATION:
        render2FARegistrationStep = (
          <>
            <h2>Wait. Before you proceed.</h2>
            <div className={styles.intructionSteps_body}>
              You are already registered with an authenticator app. By clicking &quot;CONTINUE&quot;, you will invalidate the current my529 authentication security code.
            </div>
            <br />
            <div className={styles.intructionSteps_body}>
              If you do not want to invalidate the security code, click &quot;CANCEL&quot;.
            </div>
            <br />
            <div className={styles.intructionSteps_body}>
              If you wish to register other authenticator, click &quot;CONTINUE&quot;. You will then need to scan the QR code presented in the next popup window and follow the instructions to set up a two-factor authentication with an authenticator app.
            </div>

            <div style={{ marginTop: '30px', marginBottom: '10px' }}>
              <Button
                variant='contained'
                className={styles.buttons}
                onClick={() => this.setState({ registrationStep: REGISTRATION_STEPS.VERIFY_OTHER_AUTHORIZATION })}
              >
                Continue
              </Button>
            </div>
            {useAnotherMethodButton()}
            {cancelButton()}
          </>
        );
        break;

      case REGISTRATION_STEPS.VERIFY_OTHER_AUTHORIZATION:
        render2FARegistrationStep = (
          <>
            <RegisterAuthenticator
              registerType={TWO_FACTOR_TYPES.UPDATE}
              onVerificationSuccess={this.onVerificationSuccess}
              onVerificationFail={this.logOut}
            />
            {!isINAPPTOKEN && cancelButton()}
          </>
        );
        break;

      default: // do nothing
    }

    return (
      <>
        <div className={parentStyles.myInfo_right_container}>
          <div className={parentStyles.myInfo_textInput}>
            <TextField
              disabled={true}
              label='Phone Number'
              value={preferredInfo.IsRegistered ? twoFAPhoneNumberLastFour : ''}
              fullWidth
              style={{ paddingBottom: '15px' }}
              InputProps={{
                endAdornment: <InputAdornment position='end'>
                  <IconBtnTooltip
                    icon='edit'
                    onClick={this.onClickPhoneNumberEdit}
                    title='Edit'
                  />
                </InputAdornment>,
              }}
            />
          </div>

          <div>Other Authenticator Options</div>
          <div className={parentStyles.myInfo_2FA_otherMethods}>
            <Button
              color='secondary'
              variant='text'
              style={{ width: '70px', textAlign: 'center' }}
              onClick={this.onClickAuthy}
            >
              Authy
            </Button>

            <Button
              color='secondary'
              variant='text'
              onClick={this.onClickOtherAuthenticator}
            >
              Other Authenticator
            </Button>
          </div>
        </div>

        <Dialog open={isRegistrationOpen} onClose={null}>
          <div className={styles.container}>
            {render2FARegistrationStep}

            <div>
              <Button
                color='secondary'
                variant='outlined'
                style={{ marginTop: '10px' }}
                onClick={() => window.open('https://advisor.my529.org/faq-two-factor-authentication/', '_blank', 'noopener noreferrer')}
                className={styles.buttons}
              >
                Learn More
              </Button>
            </div>

            <div>
              <img src={PwddByAuthy} width='174px' alt='Powered By Authy' />
            </div>

          </div>
        </Dialog>
      </>
    );
  }
}

export default connect(select, {
  twoFactorRegistrationUpdate,
  getTwoFactorToken,
  notificationShow,
  allNotificationsHide,
  userLogout,
  clearStore,
  savePreferredMethod
})(TwoFA);
