/*
*
* Password Component
*
*/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { passwordChange } from '../actions';

import {
  TextField,
  Button,
  InputAdornment,
} from '@mui/material';

import {
  PasswordRequirements,
  LoadingOverlay,
  notificationShow,
  IconBtnTooltip,
} from '@frontend/common';

import styles from '../styles.module.css';

const select = (state) => ({
  passwordRequirements: state.static.passwordRequirements,
});

export class Password extends React.Component {
  static propTypes = {
    passwordRequirements: PropTypes.array.isRequired,
    passwordChange: PropTypes.func.isRequired,
    notificationShow: PropTypes.func.isRequired,
  };

  state = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    currentPasswordError: '',
    newPasswordError: '',
    newPasswordConfirmError: '',
    passwordIsValid: false,
    loading: false,
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  }

  handleClickShowPassword = (keyName) => {
    this.setState({ [keyName]: !this.state[keyName] });
  }


  onInputChange = (e, keyName) => {
    // clear also error
    this.setState({
      [keyName]: e.target.value,
      [`${keyName}Error`]: ''
    });
  }

  disableSubmitButton = () => {
    const { currentPassword, newPassword } = this.state;

    const err_field_required = 'Field is required.';

    let currentPasswordError = '';
    let newPasswordError = '';

    if (!currentPassword) {
      currentPasswordError = err_field_required;
    }

    if (!newPassword) {
      newPasswordError = err_field_required;
    }

    return Boolean(currentPasswordError) || Boolean(newPasswordError);
  }

  passwordInputsValidate() {
    const { newPassword, newPasswordConfirm, passwordIsValid } = this.state;

    const err_field_required = 'Field is required.';
    const err_fails_requirements = 'Password does not meet criteria.';
    const err_confirmed_not_match = 'Confirmed password does not match above password.';

    const currentPasswordError = '';
    let newPasswordError = '';
    let newPasswordConfirmError = '';

    if (!newPasswordConfirm) {
      newPasswordConfirmError = err_field_required;
    }

    if (newPassword && !passwordIsValid) {
      newPasswordError = err_fails_requirements;
    }

    if (newPassword && newPasswordConfirm && newPassword !== newPasswordConfirm) {
      newPasswordConfirmError = err_confirmed_not_match;
    }

    this.setState({ currentPasswordError, newPasswordError, newPasswordConfirmError });
    const hasError = Boolean(currentPasswordError) || Boolean(newPasswordError) || Boolean(newPasswordConfirmError);

    return !hasError;
  }

  onSubmitPassword = (e) => {
    e.preventDefault();
    if (this.passwordInputsValidate()) {
      this.setState({ loading: true });
      this.props.passwordChange(this.state.currentPassword, this.state.newPassword, this.state.newPasswordConfirm)
        .then(() => {
          this.props.notificationShow('Password updated.', 'success');
          this.setState({
            currentPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
          });
        })
        .catch((response) => {
          response.payload.data.forEach(error => {
            if (error.Field === 'OldPassword') {
              this.setState({ currentPasswordError: error.Message });
            }
          });
        })
        .finally(() => this.setState({ loading: false }));
    }
  }

  render() {
    const {
      loading, currentPassword, newPassword, newPasswordConfirm,
      currentPasswordError, newPasswordError, newPasswordConfirmError,
      showCurrentPassword, showNewPassword, showConfirmPassword,
    } = this.state;

    return (
      <form className={styles.myInfo_right_container} onSubmit={this.onSubmitPassword}>
        <div className={styles.myInfo_textInput}>
          <TextField
            autoComplete='new-password'
            label='Current Password'
            name='currentPassword'
            type={showCurrentPassword ? 'text' : 'password'}
            value={currentPassword}
            onChange={(e) => this.onInputChange(e, 'currentPassword')}
            error={Boolean(currentPasswordError)}
            helperText={currentPasswordError}
            disabled={loading}
            fullWidth
            InputProps={{ 
              endAdornment: (
                <InputAdornment position='end'>
                  <IconBtnTooltip
                    icon={showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    onClick={() => this.handleClickShowPassword('showCurrentPassword')}
                    title={'Toggle password visibility'}
                  />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={styles.myInfo_textInput}>
          <TextField
            autoComplete='new-password'
            label='New Password'
            name='newPassword'
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            onChange={(e) => this.onInputChange(e, 'newPassword')}
            error={Boolean(newPasswordError)}
            helperText={newPasswordError}
            disabled={loading}
            fullWidth
            InputProps={{ 
              endAdornment: (
                <InputAdornment position='end'>
                  <IconBtnTooltip
                    icon={showNewPassword ? <Visibility /> : <VisibilityOff />}
                    onClick={() => this.handleClickShowPassword('showNewPassword')}
                    title={'Toggle password visibility'}
                  />
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={styles.myInfo_textInput}>
          <TextField
            autoComplete='new-password'
            label='Confirm New Password'
            name='newPasswordConfirm'
            type={showConfirmPassword ? 'text' : 'password'}
            value={newPasswordConfirm}
            onChange={(e) => this.onInputChange(e, 'newPasswordConfirm')}
            error={Boolean(newPasswordConfirmError)}
            helperText={newPasswordConfirmError}
            disabled={loading}
            fullWidth
            InputProps={{ 
              endAdornment: (
                <InputAdornment position='end'>
                  <IconBtnTooltip
                    icon={showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    onClick={() => this.handleClickShowPassword('showConfirmPassword')}
                    title={'Toggle password visibility'}
                  />
                </InputAdornment>
              )
            }}

          />
        </div>
        <div className={styles.myInfo_textInput}>
          <PasswordRequirements
            password={newPassword}
            passwordRequirements={this.props.passwordRequirements}
            onPasswordCheck={(passwordIsValid) => this.setState({ passwordIsValid })}
          />
        </div>
        <div className={styles.myInfo_button}>
          <LoadingOverlay show={loading}>
            <Button
              type='submit'
              variant='contained'
              disabled={this.disableSubmitButton()}
            >
              Change Password
            </Button>
          </LoadingOverlay>
        </div>
      </form>
    );
  }
}

export default connect(select, {
  passwordChange,
  notificationShow
})(Password);
